@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@layer base {
  html {  
    font-family: "Noto Sans", sans-serif;
  }
}

html, body {
  font-family: "Noto Sans", sans-serif;
}

html,
body {
  height: 100%;
}
.spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  left: calc(50vw - 25px);
  top: calc(50vh - 20px);
}

.spinner > div {
  z-index: 999;
  background-color: black;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.yt-toogle.collapsed .fa-caret-down {
  display: none;
}
.yt-toogle.collapsed .fa-caret-right {
  display: inline-block;
}
.yt-toogle .fa-caret-right {
  display: none;
}
.indian-flag
{
    height: 100%;
    width: 100%;
    background-image: url("/indian-flag.jpg");
}
